import React from 'react'
import { useCartState } from '../context/CartContext'
import CartItems from './CartItems'
import { useMutation, useQuery } from '@apollo/client'
import UPDATE_CART from '../mutations/UpdateCart'
import CLEAR_CART_MUTATION from '../mutations/ClearCart'
import {
  Box,
  Heading,
  HStack,
  Link,
  Stack,
  Button,
  Flex,
  Text,
  useToast,
  Center,
} from '@chakra-ui/react'
import { CartType } from '../../../types/Cart'
import GET_CART from '../queries/GetCart'
import { CartOrderSummary } from './CartOrderSummary'

const CartItemsContainer = () => {
  const { cart, setCart } = useCartState()

  const toast = useToast()

  // Get Cart Data.
  const { data, refetch, loading } = useQuery<CartQuery>(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // Update cart data in React Context.
      console.log(data?.cart)
      setCart(data?.cart)
    },
  })

  if (!cart) {
    refetch()
  }

  // Update Cart Mutation.
  const [updateCart, { loading: updateCartProcessing }] =
    useMutation<CartQuery>(UPDATE_CART, {
      onCompleted: ({ cart }) => {
        setCart(cart)
      },
      onError: (error) => {
        console.log(error.message)
        //add error display mechanism
        toast({
          title: 'Error',
          description: 'Cart update failed',
          status: 'error',
        })
      },
    })

  // Clear Cart.
  const [clearCart, { loading: clearCartProcessing }] = useMutation<CartQuery>(
    CLEAR_CART_MUTATION,
    {
      onCompleted: ({ cart }) => {
        setCart(cart)
      },
      onError: (error) => {
        if (error) {
          console.log(error.message)
          toast({
            title: 'Error',
            description: 'Cart Update Failed',
            status: 'error',
          })
        }
      },
    }
  )

  /*
   * When user changes the qty from product input update the cart in localStorage
   * Also update the cart in global context
   *
   * @param {Object} event event
   *
   * @return {void}
   */

  const handleQtyChange = (cartKey: string, type: string, qty: number) => {
    if (typeof window !== 'undefined') {
      let newQty: number

      // If the previous update cart mutation request is still processing, then return.
      if (updateCartProcessing || ('decrement' === type && 0 === qty)) {
        return
      }

      if ('decrement' === type) newQty = qty - 1
      else if ('increment' === type) newQty = qty + 1
      else newQty = 0

      updateCart({
        variables: {
          input: {
            clientMutationId: 'UPDATE_CART_QTY',
            items: [{ key: cartKey, quantity: newQty }],
          },
        },
      })
    }
  }

  /*
   * Handle remove product click.
   *
   * @param {Object} event event
   * @param {Integer} Product Id.
   *
   * @return {void}

  const handleRemoveProductClick = (cartKey: string) => {
    // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
    const newQty = 0

    updateCart({
      variables: {
        input: {
          clientMutationId: 'CART_UPDATE',
          items: [{ key: cartKey, quantity: newQty }],
        },
      },
    })
  }*/

  // Clear the entire cart.
  const handleClearCart = () => {
    if (clearCartProcessing) {
      return
    }

    clearCart({
      variables: {
        input: {
          clientMutationId: 'CART_CLEAR',
          all: true,
        },
      },
    })
  }

  if (loading) {
    return (
      <Center w='full'>
        <Button isLoading={loading} h='300' w='300' />
      </Center>
    )
  }
  if (!cart || cart?.contents.itemCount === 0) {
    return (
      <>
        <Center mt='200'>
          <Box>
            <Text as='p' fontWeight='bold'>
              You do not have any items in the cart.
            </Text>
          </Box>
        </Center>
        <Center>
          <Box mt='20px'>
            <Link href='/' color='blue.500'>
              Continue shopping
            </Link>
          </Box>
        </Center>
      </>
    )
  }

  const subTotal = cart?.subtotal ? cart.subtotal : ''
  const shippingTotal = cart?.shippingTotal ? cart.shippingTotal : ''
  const total = cart?.total ? cart.total : ''
  const itemCount = cart?.contents.itemCount

  console.log(cart)

  return (
    <>
      <Box
        maxW={{ base: '3xl', lg: '7xl' }}
        mx='auto'
        px={{ base: '8', md: '12' }}
        py={{ base: '8', md: '12' }}
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          align={{ lg: 'flex-start' }}
          spacing={{ base: '8', md: '16' }}
        >
          <Stack spacing={{ base: '8', md: '10' }} flex='2'>
            <Heading fontSize='2xl' fontWeight='extrabold'>
              Shopping Cart ({itemCount} items)
            </Heading>
            <Stack spacing='6'>
              {cart?.contents.nodes.map((item) => (
                <CartItems
                  key={item.key}
                  cartItem={item}
                  handleQtyChange={handleQtyChange}
                  updateCart={updateCart}
                  updateCartProcessing={updateCartProcessing}
                />
              ))}
            </Stack>
            <Button
              isLoading={clearCartProcessing}
              onClick={() => handleClearCart()}
            >
              Remove all
            </Button>
          </Stack>

          <Flex direction='column' align='center' flex='1'>
            <CartOrderSummary
              subTotal={subTotal}
              shippingTotal={shippingTotal}
              total={total}
            />
            <HStack mt='6' fontWeight='semibold'>
              <p>or</p>
              <Link color='blue.500' href='/'>
                Continue shopping
              </Link>
            </HStack>
          </Flex>
        </Stack>
      </Box>
    </>
  )
}

export default CartItemsContainer

type CartQuery = {
  cart: CartType
}
