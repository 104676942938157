import {
  Box,
  Image,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'

export type CartProductMetaProps = {
  name: string
  image: string
}

export const CartProductMeta = (props: CartProductMetaProps) => {
  const { image, name } = props
  return (
    <Stack direction='row' spacing='5' width='full' align='center'>
      {
        <Image
          rounded='lg'
          width='80px'
          height='80px'
          fit='cover'
          src={image}
          alt={name}
          draggable='false'
          loading='lazy'
        />
      }
      <Box pt='4' align='center'>
        <Text fontWeight='medium'>{name}</Text>
      </Box>
    </Stack>
  )
}
