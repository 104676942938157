import { gql } from '@apollo/client'

const GET_CART = gql`
  query Cart {
    cart {
      subtotal
      total
      shippingTotal
      contents {
        itemCount
        nodes {
          key
          quantity
          subtotal
          product {
            node {
              name
              databaseId
              image {
                sourceUrl
              }
              ... on SimpleProduct {
                price
                salePrice
                regularPrice
              }
            }
          }
        }
      }
    }
  }
`
export default GET_CART
