import { gql } from '@apollo/client'

const CLEAR_CART_MUTATION = gql`
  mutation CLEAR_CART_MUTATION($input: RemoveItemsFromCartInput!) {
    removeItemsFromCart(input: $input) {
      cart {
        subtotal
        total
        shippingTotal
        contents {
          itemCount
          nodes {
            key
            quantity
            subtotal
            product {
              node {
                name
                databaseId
                image {
                  sourceUrl
                }
                ... on SimpleProduct {
                  price
                  salePrice
                  regularPrice
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CLEAR_CART_MUTATION
