import jwtDecode from 'jwt-decode'
import { useState } from 'react'

export function checkoutURL(): string | undefined {
  const [session] = useState(() => {
    try {
      // Get the session from localstorage
      const jwtSession = window.localStorage.getItem('woo-session')

      // jtw-decode is an open-source package
      const decoded = jwtDecode<{ data: { customer_id: string } }>(jwtSession!)
      console.log(decoded)
      return decoded.data.customer_id
    } catch (error) {
      console.error(error)
      console.log(error)
      return null
    }
  })

  const url = process.env.GATSBY_WP_URL?.concat(
    `/checkout/?session_id=${session}`
  )
  return url
}
