import React from 'react'
import CartItemsContainer from '../components/cart/CartItemContainer'
import Layout from '../components/common/Layout'

const cartPage = () => {
  return (
    <Layout>
      <CartItemsContainer />
    </Layout>
  )
}

export default cartPage
