import { CloseButton, Flex, Text, Link, HStack, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import { CartProductMeta } from './CartProductMeta'
import { CartItem } from '../../../types/CartItem'

type CartItemProps = {
  cartItem: CartItem
  updateCartProcessing: boolean
  handleQtyChange: any
  updateCart: any
}

const CartItems: React.FC<CartItemProps> = ({
  cartItem,
  updateCartProcessing,
  handleQtyChange,
}) => {
  //console.log(cartItem)
  const name = cartItem.product.node.name ? cartItem.product.node.name : ''
  const imageUrl = cartItem.product.node.image?.sourceUrl
  let quantity = cartItem.quantity ? cartItem.quantity : 1
  //const price = cartItem.product.node.price
  const price = cartItem.product.node.price
  const subTotal = cartItem.subtotal
  const [productCount, setProductCount] = useState(quantity)

  useEffect(() => {
    setProductCount(quantity)
  }, [quantity])

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      justify='space-between'
      align='center'
    >
      <CartProductMeta name={name} image={imageUrl} />

      {/* Desktop */}
      <Flex
        width='full'
        justify='space-between'
        align='center'
        display={{ base: 'none', md: 'flex' }}
      >
        <HStack fontSize='20px' align='center' textAlign='center'>
          <Text fontSize='15px'>{price}</Text>
          <Button
            w='15px'
            ml='5'
            bgColor='#d32f2f'
            fontSize='20px'
            color='white'
            _hover={{
              bg: 'gray.200',
              color: 'black',
            }}
            onClick={() =>
              handleQtyChange(cartItem.key, 'decrement', productCount)
            }
            isLoading={updateCartProcessing}
          >
            -
          </Button>
          <Text ml='2' mr='2' fontSize='18px' color='black' readOnly>
            {productCount}
          </Text>
          <Button
            w='15px'
            ml='5'
            bgColor='#d32f2f'
            fontSize='20px'
            color='white'
            _hover={{
              bg: 'gray.200',
              color: 'black',
            }}
            onClick={() =>
              handleQtyChange(cartItem.key, 'increment', productCount)
            }
            isLoading={updateCartProcessing}
          >
            +
          </Button>
        </HStack>

        <Text fontWeight='bold'>{subTotal}</Text>

        <CloseButton
          aria-label={`Delete ${name} from cart`}
          onClick={() => handleQtyChange(cartItem.key, 'remove')}
          isLoading={updateCartProcessing}
        />
      </Flex>

      {/* Mobile */}
      <Flex
        mt='4'
        align='center'
        width='full'
        justify='space-between'
        display={{ base: 'flex', md: 'none' }}
      >
        <Link fontSize='sm' textDecor='underline'>
          Delete
        </Link>
        <HStack>
          <Button
            w='10px'
            ml='5'
            bgColor='#d32f2f'
            fontSize='20px'
            color='white'
            _hover={{
              bg: 'gray.200',
              color: 'black',
            }}
            onClick={() => handleQtyChange(cartItem.key, 'decrement')}
            isLoading={updateCartProcessing}
          >
            -
          </Button>
          <Text ml='1' mr='1' fontSize='15' color='black' readOnly>
            {productCount}
          </Text>
          <Button
            w='10px'
            ml='5'
            bgColor='#d32f2f'
            fontSize='20px'
            color='white'
            _hover={{
              bg: 'gray.200',
              color: 'black',
            }}
            onClick={() => handleQtyChange(cartItem.key, 'increment')}
          >
            +
          </Button>
        </HStack>
        {price}
      </Flex>
    </Flex>
  )
}

export default CartItems
