import {
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { ArrowRightIcon } from '@chakra-ui/icons'
import { checkoutURL } from '../context/checkoutURL'

type OrderSummaryItemProps = {
  label: string
  value?: string
  children?: React.ReactNode
}

type OrderSummaryProps = {
  subTotal: string
  shippingTotal: string
  total: string
}

const OrderSummaryItem = (props: OrderSummaryItemProps) => {
  const { label, value, children } = props
  return (
    <Flex justify='space-between' fontSize='sm'>
      <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
        {label}
      </Text>
      {value ? <Text fontWeight='medium'>{value}</Text> : children}
    </Flex>
  )
}

export const CartOrderSummary = (props: OrderSummaryProps) => {
  const { subTotal, shippingTotal, total } = props

  return (
    <Stack spacing='8' borderWidth='1px' rounded='lg' padding='8' width='full'>
      <Heading size='md'>Order Summary</Heading>

      <Stack spacing='6'>
        <OrderSummaryItem label='Subtotal' value={subTotal} />

        {shippingTotal === '£0.00' ? (
          <OrderSummaryItem label='Shipping'>
            <Link href='#' textDecor='underline'>
              Calculate shipping
            </Link>
          </OrderSummaryItem>
        ) : (
          <OrderSummaryItem label='Shipping + Tax' value={shippingTotal} />
        )}
        <OrderSummaryItem label='Coupon Code'>
          <Link href='#' textDecor='underline'>
            Add coupon code
          </Link>
        </OrderSummaryItem>
        <Flex justify='space-between'>
          <Text fontSize='lg' fontWeight='semibold'>
            Total
          </Text>
          <Text fontSize='xl' fontWeight='extrabold'>
            {total}
          </Text>
        </Flex>
      </Stack>
      <Button
        as='a'
        href={checkoutURL()}
        colorScheme='blue'
        size='lg'
        fontSize='md'
        rightIcon={<ArrowRightIcon />}
      >
        Checkout
      </Button>
    </Stack>
  )
}
